/* Slider Animation */

.slide h1 {
  transition: all 0.3s ease;
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
  opacity: 0;
}

.slide h2 {
  transition: all 0.3s ease;
  -webkit-transform: translateX(-20px);
  transform: translateX(-20px);
  opacity: 0;
}

.slide .btn-box {
  transition: all 0.3s ease;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
}

.slide .text {
  transition: all 0.3s ease;
  -webkit-transform: translateX(20px);
  transform: translateX(20px);
  opacity: 0;
}

.slide section * {
  transition: all 0.3s ease;
}

.slide.animateIn.previous h1,
.slide.current h1,
.slide.current h2,
.slide .btn-box,
.slide .text,
.slide.animateIn.next h1,
.slide.animateIn.previous button,
.slide.current button,
.slide.animateIn.next button,
.slide.animateIn.previous p,
.slide.current p,
.slide.animateIn.next p,
.slide.animateIn.previous section *,
.slide.current section *,
.slide.animateIn.next section * {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition-delay: 0.9s;
  transition-delay: 0.9s;
  opacity: 1;
}

.slide.animateIn.previous p,
.slide.animateIn.next p {
  -webkit-transition-delay: 1.1s;
  transition-delay: 1.1s;
}

.slide.animateIn.previous button,
.slide.animateIn.next button {
  -webkit-transition-delay: 1.3s;
  transition-delay: 1.3s;
}

.slide.animateIn.previous section img,
.slide.animateIn.next section img {
  -webkit-transition-delay: 1.3s;
  transition-delay: 1.3s;
}

.slide.animateIn.previous section span,
.slide.animateIn.next section span {
  -webkit-transition-delay: 1.4s;
  transition-delay: 1.4s;
}

.slide.animateIn.previous section span strong,
.slide.animateIn.next section span strong {
  -webkit-transition-delay: 1.5s;
  transition-delay: 1.5s;
}

.slide.animateOut h1 {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.slide.animateOut h2 {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.slide.animateOut section span {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.slide.animateOut section span strong {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

/* Home Slider */

.slider-wrapper {
  position: relative;
  overflow: hidden;
  height: 900px;
}

.slide {
  height: 900px;
  background-size: cover !important;
}

.slide::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(transparent),
    to(rgba(0, 0, 0, 0.9))
  );
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.9));
  bottom: 0;
  left: 0;
}

.previousButton,
.nextButton {
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  background: url("../images/MM9.svg") no-repeat center center / 16px;
  width: 64px;
  height: 64px;
  text-indent: -9999px;
  cursor: pointer;
}

.previousButton:hover,
.nextButton:hover {
  background: url("../images/MM9.svg") no-repeat center center / 16px;
}

.previousButton {
  left: 0;
  -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
  transform: rotate(180deg) translateY(calc(50% + 0px));
}

.previousButton:hover {
  left: -10px;
}

.nextButton {
  right: 0;
}

.nextButton:hover {
  right: -10px;
}

.slider-content .inner {
  padding: 0 70px;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -25%);
  transform: translate(-50%, -25%);
}

.slider-content .inner button {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.slider-content .inner h1 {
  display: block;
  font-size: 24px;
  line-height: 1.15em;
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 20px;
  letter-spacing: 0;
  -webkit-transform: translateY(-100px);
  -ms-transform: translateY(-100px);
  transform: translateY(-100px);
  text-transform: capitalize;
}

.slider-content .inner h2 {
  display: block;
  font-size: 180px;
  line-height: 1.15em;
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 0;
  letter-spacing: -10px;
  position: relative;
  left: -10px;
  -webkit-transform: translateY(-100px);
  -ms-transform: translateY(-100px);
  transform: translateY(-100px);
  text-transform: capitalize;
}

.slider-content .inner.inner-2 h2 {
  font-size: 120px;
  letter-spacing: -5px;
  line-height: 1em;
  margin-bottom: -85px;
}

.slider-content .inner.inner-2 h2 span {
  font-weight: 700;
  display: block;
}
.slider-content .inner h2 span {
  font-weight: 300;
}

.slider-content .inner .text {
  display: block;
  font-size: 20px;
  line-height: 1.6em;
  color: #ffffff;
  font-weight: 400;
  margin-bottom: -80px;
  -webkit-transform: translateY(-120px);
  -ms-transform: translateY(-120px);
  transform: translateY(-120px);
  padding-right: 700px;
}

.slider-content section {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.slider-content section span {
  color: #ffffff;
}

.slider-content section span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  display: inline-block;
  text-align: left;
  line-height: 1.4;
  vertical-align: middle;
  margin-left: 10px;
}

.slider-content section img {
  width: 40px;
  height: 40px;
  border: solid 2px rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  vertical-align: middle;
}

.slider-content section span strong {
  color: #ffffff;
  font-size: 14px;
  display: block;
}

@media (max-height: 500px) {
  .slider-wrapper,
  .slide {
    height: calc(100vh - 75px);
  }
}

@media (max-width: 640px) {
  .slider-wrapper,
  .slide {
    height: calc(80vh - 75px);
  }
}

.banner-section.style-four .slider-wrapper .slide::before {
  background-image: linear-gradient(
    90deg,
    rgba(209, 100, 26, 1) 0%,
    rgba(192, 40, 42, 1) 100%
  );
  opacity: 0.9;
}
.banner-section.style-four .slider-wrapper .slide .inner {
  top: 43%;
}

.banner-section.style-four .slider-wrapper .slide .inner h2 {
  font-size: 120px;
  margin-bottom: -85px;
}
.banner-section.style-four .slider-wrapper .slide .inner h2 span {
  font-weight: 700;
  display: block;
}
